// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inti-voxel-energy-dash-privacy-policy-js": () => import("./../../../src/pages/inti-voxel/energy-dash/privacy-policy.js" /* webpackChunkName: "component---src-pages-inti-voxel-energy-dash-privacy-policy-js" */),
  "component---src-pages-inti-voxel-index-js": () => import("./../../../src/pages/inti-voxel/index.js" /* webpackChunkName: "component---src-pages-inti-voxel-index-js" */),
  "component---src-pages-inti-voxel-matter-presskit-js": () => import("./../../../src/pages/inti-voxel/matter/presskit.js" /* webpackChunkName: "component---src-pages-inti-voxel-matter-presskit-js" */),
  "component---src-pages-web-about-js": () => import("./../../../src/pages/web/about.js" /* webpackChunkName: "component---src-pages-web-about-js" */)
}

